<template>
<div>
    <div>
        <div class="Header">
            <div class="pull_func">
                <ButtonGroup size="small" shape="circle">
                    <Button>Created</Button>
                    <Button>Assigned</Button>
                    <Button>Mentioned</Button>
                    <Button>Review requests</Button>
                </ButtonGroup>
            </div>
            <div class="search_func">
                <Input search size="small" placeholder="Enter something..." />
            </div>
        </div>

        <div class="Body" >
            <div style="width: 100%;float:left;background-color: white ;height: 40px">
                <div style="float:left; padding: 10px 4% ">
                        0 open 0 close
                </div>
                <div class="body_filter" style="float:right;color: white; padding: 10px 5% ">
                    <div style="float: left">
                            <Dropdown placement="bottom-start">
                                <a href="javascript:void(0)">
                                   Visibility
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem>Private repositories only</DropdownItem>
                                    <DropdownItem>Public repositories only</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown style="margin-left: 20px">
                                <a href="javascript:void(0)">
                                    Organizations
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem>owner1</DropdownItem>
                                    <DropdownItem>owner2</DropdownItem>
                                    <DropdownItem>organization1</DropdownItem>
                                    <DropdownItem>organization2</DropdownItem>
                                    <DropdownItem>organization3</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown style="margin-left: 20px" placement="bottom-end">
                                <a href="javascript:void(0)">
                                    Sort by
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem>Newest</DropdownItem>
                                    <DropdownItem>Oldest</DropdownItem>
                                    <DropdownItem>Most commented</DropdownItem>
                                    <DropdownItem>Least commented</DropdownItem>
                                    <DropdownItem>Recently update</DropdownItem>
                                    <DropdownItem>Least recently update</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                    </div>
                </div>
            </div>
            <div style="width:95%;height:1px;margin:0px 2%;background-color:#D5D5D5;overflow:hidden;"></div>

            <div style="height:200px;background-color: white">
                <strong>No results matched your search.
                </strong>
            </div>


        </div>
    </div>

</div>
</template>

<script>
    export default {
        name: "userProjects"
    }
</script>

<style scoped>
    .Header{
        height: 35px;
        float: left;
    }

    .pull_func{
        width: 50%;
        float: left;
    }

    .search_func{
        widtH:30%;
        margin-left: 5%;
        float: left;
    }
    .Body{
        float: left;
        width: 100%;
    }
    .body_filter{
        color: lightgray;
    }


</style>
